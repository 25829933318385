import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
    ignoreKeyInHTML,
    rqStatusMap,
    SUPPORTED_LANGUAGES_CULTURES,
    TRANSLATIONS_NAMESPACE,
} from '../../constants/global'
import { useSelector } from 'react-redux'
import { getTranslations } from '../../actions/i18nActions'
import { Accounts, Customers } from '../../api'
import { CircularProgress, Grid } from '@material-ui/core'
import { useChangeLanguage } from '../../hooks/useChangeLanguage'
import { dateFormats, defaultCalendarFormat } from '../../constants/dateFormats'
import { DateTime } from 'luxon'

const SeatTitle = styled.h1`
    font-size: 40px;
`

const SeatSubtitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.3;
    margin-bottom: 20px;
`

const CupraTitle = styled.h1`
    color: #954f2b;
    font-weight: normal;
    font-size: 34px;
    letter-spacing: 2px;
`

const CupraSubtitle = styled.div`
    color: #954f2b;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.3;
    margin-bottom: 20px;
`

const BodyText = styled.div`
    font-weight: normal;
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 20px;
`

const ConsentItem = styled.div`
    position: relative;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.3;
    padding-left: 20px;
    margin-bottom: 10px;

    &:before {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 1;
        left: 0;
        top: 0;
        width: 12px;
        height: 12px;
        border: solid black 1px;
        ${({ isChecked }) => (isChecked ? `content: '\\00d7'` : `content: ''`)}
    }
`

const CaptionText = styled.div`
    font-size: 9px;
    line-height: 1.2;
`

const FooterText = styled.div`
    font-size: 9px;
    line-height: 1.2;
`

const InputField = styled.div`
    font-size: 12px;
    line-height: 1.2;
    padding-top: 6px;
    margin-right: 10px;
    border-top: solid black 1px;
`

const CupraLogo = styled.div`
    color: #954f2b;
`

const SeatLogo = styled.div`
    color: #33302e;
    margin-bottom: 20px;
`

export default function ConsentDocumentPreview() {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const [triggered, setTriggered] = useState(false)
    const { orderDetails, customerInformation } = useSelector((state) => state.customer)
    const [rqStatus, setRqStatus] = useState(rqStatusMap.IN_PROGRESS)
    const [consentsContent, setConsentsContent] = useState({})
    const [consentsConfirmationDate, setConsentsConfirmationDate] = useState(null)
    const { setLanguage } = useChangeLanguage()

    useEffect(() => {
        const url = new URL(window.location.href)
        const print = url.searchParams.get('print')

        if (print && !triggered) {
            window.setTimeout(() => {
                setTriggered(true)
                window.print()
            }, 1000)
        }
    }, [triggered])

    useEffect(() => {
        const init = async () => {
            setRqStatus(rqStatusMap.IN_PROGRESS)
            const url = new URL(window.location.href)
            const email = url.searchParams.get('email')
            const customerId = url.searchParams.get('customerId')
            const preferredServicePartner = url.searchParams.get('preferredServicePartner')
            const dealerMaintenanceScheduling = url.searchParams.get('dealerMaintenanceScheduling')
            const seatIdent = url.searchParams.get('seatIdent')
            const isUpdate = url.searchParams.get('isUpdate')
            const language = url.searchParams.get('language')

            const selectedCulture = [SUPPORTED_LANGUAGES_CULTURES[language]]

            getTranslations([selectedCulture])

            if (email) {
                try {
                    const rqData = {
                        email,
                        brand: orderDetails.brand,
                        language,
                        consents: {
                            preferredServicePartner: {
                                selected: preferredServicePartner === 'true',
                            },
                            dealerMaintenanceScheduling: {
                                selected: dealerMaintenanceScheduling === 'true',
                            },
                            seatIdent: {
                                selected: seatIdent === 'true',
                            },
                        },
                    }
                    const response = isUpdate
                        ? await Accounts.modifyConsentsPaper(rqData)
                        : await Accounts.createConsentsPaper(rqData)
                    setConsentsContent(response.data)
                    setRqStatus(rqStatusMap.SUCCESS)
                } catch (e) {
                    console.error(e)
                    setRqStatus(rqStatusMap.ERROR)
                }
            } else if (customerId) {
                try {
                    const response = await Customers.downloadConsentDigital(customerId)
                    const consentsDataFlat = {}
                    for (const item in response.data.consents) {
                        if (response.data.consents[item].selected) {
                            consentsDataFlat[item] = response.data.consents[item].content
                        }
                    }
                    setConsentsContent(consentsDataFlat)
                    setConsentsConfirmationDate(
                        DateTime.fromJSDate(response.data.confirmationDate).toFormat(
                            dateFormats[customerInformation.preferredLanguage] ??
                                defaultCalendarFormat
                        )
                    )
                    setRqStatus(rqStatusMap.SUCCESS)
                } catch (e) {
                    console.error(e)
                    setRqStatus(rqStatusMap.ERROR)
                }
            }
            setLanguage(selectedCulture)
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {rqStatus === rqStatusMap.IN_PROGRESS && <CircularProgress size={'24px'} />}
            {rqStatus === rqStatusMap.SUCCESS && (
                <div className="content-wrapper--print">
                    <div className="content-wrapper">
                        {orderDetails.brand === 'SEAT' ? (
                            <>
                                <SeatLogo>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 599.9 135.401"
                                        width="200px"
                                    >
                                        <path
                                            d="M486.6 51.15v17h-54.3v-4.8c0-7.4 1.2-12.4 10-12.4h44.4v.2zm-47.8-10.6c-14.3 0-20.9 7.6-20.9 19.8v34.3h14.3v-15.8h54.3v15.7h14.3v-54zm79.7 0v10.6h33.6v43.4h14.3v-43.4h33.5v-10.6zm-192.2 33.3c0 7.6 1.6 10.3 10 10.3h59.6v10.6h-63c-14.3 0-21-6.5-21-18.6v-35.5h83.9v10.6h-69.6v10.6h66.7v10.4h-66.7v1.8zm-102.2-14.4c-4.4-.4-5.2-1.9-5.2-4.6 0-2.4 1.3-3.8 4.7-3.8h65.9v-10.4h-67.6c-9.6 0-16.2 3.7-16.2 14.4s4.9 14.4 16 15.4l53.4 4.4c4.1.4 5.3 1.7 5.3 4.7 0 2.8-1.2 4.6-4.9 4.6h-67.1v10.6h68.1c11.8 0 16.9-4.7 16.9-15.7 0-10.1-4.7-14.2-16.1-15.1zM.2 49.15c.1-4.9.5-16.8.7-23.8.4-9 2.2-14.4 5.9-17.8 5.2-4.6 11-5.6 25.3-6.4 33-1.8 66.6-1.6 108.7 1.4 4.4.4 6 1.1 7 1.7 1.9 1.3 2 2.8 2.6 10.2.2 4.3.8 15.7 1.2 22-14.5-.7-39.1-2-60.6-2.3-21.5-.3-40.8.2-43.1.6-4.7.6-6.8 2.8-7.4 3.7L152 59.05v16.2zm-.1 7.9 151.4 28.3c-.1 4.1-.5 17.2-.7 24.7-.4 9-2.2 14.4-5.9 17.8-5.2 4.6-11 5.6-25.3 6.4-33 1.8-66.6 1.6-108.7-1.4-4.4-.4-6-1.1-7-1.7-1.9-1.3-2-2.8-2.6-10.2-.4-5-1.1-19.8-1.3-24.6 19.6 1.3 49.6 2.3 74 2.3 6.1 0 22.1.2 26.4.2 3.4 0 7.8-1 10.1-4.4L.1 75.35z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </SeatLogo>
                                <SeatTitle>SEAT CONNECT</SeatTitle>
                                <SeatSubtitle>
                                    {parse(
                                        t('general.documents.consent.agreement.title', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </SeatSubtitle>
                                <BodyText>
                                    {parse(
                                        t('general.documents.consent.agreement.content', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </BodyText>
                                <div style={{ marginBottom: '30px' }}>
                                    {Object.entries(consentsContent)
                                        // MSP-35773
                                        .filter(([key]) => key !== 'seatIdent')
                                        .map(([key, value]) => (
                                            <ConsentItem
                                                key={key}
                                                isChecked={!!consentsConfirmationDate}
                                            >
                                                {value}
                                            </ConsentItem>
                                        ))}
                                </div>
                                <BodyText>
                                    {parse(
                                        t('general.documents.consent.agreement.acknowledge', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </BodyText>
                                <CaptionText style={{ marginBottom: '80px' }}>
                                    {parse(
                                        t('general.documents.consent.agreement.asterisk', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </CaptionText>
                                <Grid container spacing={6} style={{ marginBottom: '60px' }}>
                                    <Grid item xs={5}>
                                        {consentsConfirmationDate ? (
                                            <BodyText>{consentsConfirmationDate}</BodyText>
                                        ) : (
                                            <InputField>
                                                {parse(
                                                    t('general.documents.consent.date', {
                                                        replace: ignoreKeyInHTML,
                                                    })
                                                )}
                                            </InputField>
                                        )}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {consentsConfirmationDate ? (
                                            <BodyText>
                                                {customerInformation.firstName}{' '}
                                                {customerInformation.surname}
                                            </BodyText>
                                        ) : (
                                            <InputField>
                                                {parse(
                                                    t('general.documents.consent.signature', {
                                                        replace: ignoreKeyInHTML,
                                                    })
                                                )}
                                            </InputField>
                                        )}
                                    </Grid>
                                </Grid>
                                <FooterText>
                                    {parse(
                                        t('general.documents.consent.agreement.footer', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </FooterText>
                            </>
                        ) : (
                            <>
                                <CupraTitle>CUPRA CONNECT</CupraTitle>
                                <CupraSubtitle>
                                    {parse(
                                        t('general.documents.consent.agreement.title2', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </CupraSubtitle>
                                <BodyText>
                                    {parse(
                                        t('general.documents.consent.agreement.content2', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </BodyText>
                                <div style={{ marginBottom: '30px' }}>
                                    {Object.entries(consentsContent)
                                        // MSP-35773
                                        .filter(([key]) => key !== 'seatIdent')
                                        .map(([key, value]) => (
                                            <ConsentItem
                                                key={key}
                                                isChecked={!!consentsConfirmationDate}
                                            >
                                                {value}
                                            </ConsentItem>
                                        ))}
                                </div>
                                <BodyText>
                                    {parse(
                                        t('general.documents.consent.agreement.acknowledge', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </BodyText>
                                <CaptionText style={{ marginBottom: '80px' }}>
                                    {parse(
                                        t('general.documents.consent.agreement.asterisk', {
                                            replace: ignoreKeyInHTML,
                                        })
                                    )}
                                </CaptionText>
                                <Grid container spacing={6} style={{ marginBottom: '60px' }}>
                                    <Grid item xs={5}>
                                        {consentsConfirmationDate ? (
                                            <BodyText>{consentsConfirmationDate}</BodyText>
                                        ) : (
                                            <InputField>
                                                {parse(
                                                    t('general.documents.consent.date', {
                                                        replace: ignoreKeyInHTML,
                                                    })
                                                )}
                                            </InputField>
                                        )}
                                    </Grid>
                                    <Grid item xs={5}>
                                        {consentsConfirmationDate ? (
                                            <BodyText>
                                                {customerInformation.firstName}{' '}
                                                {customerInformation.surname}
                                            </BodyText>
                                        ) : (
                                            <InputField>
                                                {parse(
                                                    t('general.documents.consent.signature', {
                                                        replace: ignoreKeyInHTML,
                                                    })
                                                )}
                                            </InputField>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={10}>
                                        <FooterText>
                                            {parse(
                                                t('general.documents.consent.agreement.footer2', {
                                                    replace: ignoreKeyInHTML,
                                                })
                                            )}
                                        </FooterText>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CupraLogo>
                                            <svg
                                                viewBox="0 0 313 206"
                                                width="80px"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M64.87.95c29.9 10.4 59.64 21.28 89.61 31.47 1.06.41 2.11.41 3.17 0 29.92-10.24 59.64-21.03 89.49-31.47-10.59 16.88-21.28 33.7-32.1 50.43-5-2.15-10.15-3.94-15.19-6 2.92-5.14 6.06-10.16 9.02-15.28-9.64 4.85-19.45 9.36-29.02 14.35 6.69 2.55 13.39 5.05 20.03 7.74 3.7 1.51 7.67 4.42 7.92 8.76.59 4.82-2.7 8.76-5.03 12.62-14.42 22.56-28.76 45.18-43.25 67.7.08-5.94-.6-11.94.1-17.84 5.7-18.09 11.33-36.2 16.52-54.44 1.02-2.71-2.01-4.16-3.96-5.11-5.5-2.43-10.65-5.65-16.28-7.73-5.71 2.47-11.19 5.46-16.79 8.17-1.73.8-4.17 2.38-3.18 4.64 5.15 18.25 10.84 36.35 16.5 54.45.63 5.91.03 11.91.06 17.86-14.26-22.32-28.49-44.67-42.73-67-2.48-4.11-6.08-8.31-5.46-13.44.33-4.31 4.28-7.17 7.98-8.67 6.58-2.68 13.26-5.13 19.89-7.72-9.55-4.97-19.32-9.52-28.95-14.33 2.95 5.12 6.09 10.13 9 15.28-5.07 2.02-10.2 3.89-15.25 5.96C86.19 34.6 75.52 17.77 64.87.95ZM128.23 205.19c.1-9.86-.07-19.71.09-29.57 16.22.11 32.45-.03 48.67.06 3.17-.11 6.87 1.48 7.5 4.9.39 3.35.3 6.75.06 10.1-.2 3.7-4.08 5.94-7.5 5.7-13.23.03-26.46-.02-39.7.01.01 2.96.02 5.91.06 8.87-3.06.08-6.12.04-9.18-.07m9.09-22.88c.04 2.3.06 4.6.11 6.91 12.56-.12 25.12.11 37.68-.11 2.28-1.32.73-4.49 1.16-6.61-12.97-.44-25.97-.09-38.95-.19Z"
                                                />
                                                <path
                                                    fill="currentColor"
                                                    d="M1.16 180.17c1.3-2.94 4.67-4.58 7.8-4.51 15.9-.04 31.8.02 47.71-.02.03 2.72.04 5.45.06 8.17-15.58.11-31.18-.14-46.75.12-.29.23-.86.7-1.15.94-.18 3.91.04 7.84-.12 11.76 1.41.35 2.84.71 4.31.65 14.56-.04 29.12.02 43.68-.04.01 2.63.02 5.26.02 7.89-15.25.44-30.52.06-45.77.22-3.74.15-8.38-.57-9.86-4.57-.81-3.54-.25-7.21-.4-10.8.05-3.26-.43-6.62.47-9.81ZM64.31 198.01c-.03-7.46-.05-14.92.04-22.37 3.26.01 6.53-.01 9.8.05.11 7.15-.12 14.31.14 21.46 12.1.24 24.21.1 36.31.08.5-7.18.12-14.38.23-21.56 3.3-.02 6.6-.01 9.9-.05.07 7.45.09 14.91.02 22.37.04 4-3.75 7.34-7.66 7.27-11.36.34-22.73-.11-34.1.09-3.28-.01-6.62.19-9.83-.62-2.72-1.02-5-3.71-4.85-6.72ZM192.24 175.64c15.93.04 31.86 0 47.79.01 3.44-.07 7.49 1.72 8.35 5.35.24 3.33.23 6.69-.01 10.02-.5 2.8-2.97 4.27-5.23 5.57 2.16 2.74 4.08 5.65 6.02 8.55-3.94-.25-9.92 2.11-12.12-2.35-1.32-2.1-2.57-4.24-3.85-6.36-10.53-.09-21.06-.04-31.59-.03.02 2.9.03 5.8.06 8.71-3.15.28-6.31.29-9.45.08.05-9.85-.04-19.7.03-29.55m9.46 6.34c-.08 2.4-.08 4.81-.07 7.21 12.44-.09 24.89.17 37.32-.12.31-.22.93-.67 1.25-.9-.18-2.02 1.03-5.07-1.39-6.07-12.36-.26-24.74-.01-37.11-.12ZM256.18 182.99c-.13-4.51 4.58-7.65 8.76-7.34 15.78-.01 31.56.03 47.34-.02.05 9.85.17 19.71-.12 29.56-2.74.12-5.51.38-8.24.04-1.24-2.59-.19-5.97-.49-8.84-12.68-.01-25.36 0-38.04-.01-.02 2.96 0 5.93.02 8.9-3.07.08-6.13.04-9.19-.09.09-7.4-.19-14.8-.04-22.2m9.25-.59c-.04 2.27-.03 4.54.01 6.82 12.64-.07 25.29-.02 37.94-.03.02-2.29.03-4.57.06-6.86-12.67 0-25.34-.13-38.01.07Z"
                                                />
                                            </svg>
                                        </CupraLogo>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
