import { DEFAULT_EXP_DATE } from '../constants/defaultFormValues'

export function prepareOrderDetailsForUpdate(orderDetails) {
    return {
        brand: orderDetails.brand || null,
        vin: orderDetails.vin || null,
        commissionNumber: orderDetails.commissionNumber || null,
        commissionNumberBid: orderDetails.commissionNumberBid || null,
        commissionNumberYear: orderDetails.commissionNumberYear || null,
        orderNumber: orderDetails.orderNumber || null,
        preferredServicePartnerId: orderDetails.preferredServicePartnerId || null,
        preferredServicePartnerName: orderDetails.preferredServicePartnerName || null,
        dealer: orderDetails.dealer || null,
        modelName: orderDetails.modelName || null,
    }
}

export function prepareCustomerInformationForUpdate(customerInformation) {
    return {
        email: customerInformation.email || null,
        firstName: customerInformation.firstName || null,
        surname: customerInformation.surname || null,
        nickname: customerInformation.nickname || null,
        dateOfBirth: customerInformation.dateOfBirth || null,
        address: {
            addressTypeId: 1,
            countryId: customerInformation.address?.countryId || null,
        },
        preferredLanguage: customerInformation.preferredLanguage || null,
        phoneNumber: customerInformation.phoneNumber
            ? customerInformation.phoneNumber.replace(/\s/g, '')
            : null,
        dialCode: customerInformation.dialCode || null,
        lastModifiedDate: customerInformation.lastModifiedDate || null,
    }
}

export function prepareSeatIdentForUpdate(seatIdent) {
    return {
        licenseNumber: seatIdent.licenseNumber || null,
        issueDate: seatIdent.issueDate || null,
        expirationDate: seatIdent.expirationDate || null,
        countryOfIssueCode: seatIdent.countryOfIssueCode || null,
        dateOfBirth: seatIdent.dateOfBirth || null,
    }
}

export function prepareCustomerForCreate(customer, servicePartners, accountCheck, dealer) {
    let pspName = customer?.orderDetails?.preferredServicePartnerName
    if (!pspName) {
        const psp =
            servicePartners.length === 0
                ? null
                : servicePartners.find(
                      (s) => s.id === customer.orderDetails.preferredServicePartnerId
                  )
        if (psp === undefined) {
            pspName = servicePartners.find(
                (s) => s.value === customer.orderDetails.preferredServicePartnerId
            ).name
        } else if (psp === null) {
            pspName = null
        } else {
            pspName =
                psp.name +
                ', ' +
                psp.address.street +
                ', ' +
                psp.address.city +
                ', ' +
                psp.address.zip
        }
    }

    return {
        customerInformation: {
            email: customer.customerInformation.email || null,
            firstName: customer.customerInformation.firstName || null,
            surname: customer.customerInformation.surname || null,
            nickname: customer.customerInformation.nickname || null,
            dateOfBirth: customer.customerInformation.dateOfBirth || null,
            phoneNumber:
                customer.customerInformation.dialCode && customer.customerInformation.phoneNumber
                    ? customer.customerInformation.phoneNumber?.replace(/\s/g, '')
                    : null,
            dialCode:
                customer.customerInformation.dialCode && customer.customerInformation.phoneNumber
                    ? customer.customerInformation.dialCode
                    : null,
            preferredLanguage: customer.customerInformation.preferredLanguage || null,
            address: {
                addressTypeId: 1,
                countryId: customer.customerInformation.address?.countryId || null,
            },
        },
        seatIdent: {
            licenseNumber: customer.seatIdent.licenseNumber || null,
            issueDate: customer.seatIdent.issueDate || null,
            expirationDate:
                customer.seatIdent.expirationDateCheckbox || null
                    ? DEFAULT_EXP_DATE
                    : customer.seatIdent.expirationDate,
            countryOfIssueCode: customer.seatIdent.countryOfIssueCode || null,
        },
        orderDetails: {
            vin: customer.orderDetails.vin || null,
            commissionNumber: customer.orderDetails.commissionNumber || null,
            commissionNumberBid: customer.orderDetails.commissionNumberBid || null,
            commissionNumberYear: customer.orderDetails.commissionYear || null,
            orderNumber: customer.orderDetails.orderNumber || null,
            preferredServicePartnerId: customer.orderDetails.preferredServicePartnerId || null,
            preferredServicePartnerName: pspName || null,
            dealer: {
                id: dealer.username || null,
                firstName: dealer.firstName || null,
                lastName: dealer.lastName || null,
            },
            brand: customer.orderDetails.brand || null,
            createdByOcwData: !!customer.orderDetails.createdByOcwData,
            modelName: customer.orderDetails.modelName || null,
        },
        seatConnectExistsFlag: accountCheck !== null ? accountCheck.connectAccount : false,
        seatIdentExistsFlag: accountCheck !== null ? accountCheck.seatIdAccount : false,
    }
}
