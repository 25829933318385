import { NONE } from '../constants/defaultFormValues'
import {
    ADD_ADDRESS_FIELDS,
    ADD_ADDRESS_FIELDS_ON_UPDATE,
    ADD_CUSTOMER_INFO_FROM_GROUP_ID,
    ADD_SKIPPED_STEP,
    CLEAR_NEW_CUSTOMER,
    CREATE_CUSTOMER_ERROR,
    CREATE_CUSTOMER_STARTED,
    CREATE_CUSTOMER_SUCCESS,
    GET_CUSTOMER_ERROR,
    GET_CUSTOMER_STARTED,
    GET_CUSTOMER_STATUSES_ERROR,
    GET_CUSTOMER_STATUSES_STARTED,
    GET_CUSTOMER_STATUSES_SUCCESS,
    GET_CUSTOMER_SUCCESS,
    INIT_REGISTRATION_PROCESS,
    REMOVE_ADDRESS_FIELDS,
    REMOVE_SKIPPED_STEP,
    REMOVE_SEAT_IDENT_DATA,
    RESET_VALIDATION,
    SAVE_CUSTOMER_INFORMATION,
    SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD,
    SAVE_ORDER_DETAILS,
    SAVE_SEAT_IDENT,
    SET_CUSTOMER_EMAIL,
    SET_PSP,
    UPDATE_CUSTOMER_INFORMATION_ERROR,
    UPDATE_CUSTOMER_INFORMATION_STARTED,
    UPDATE_CUSTOMER_INFORMATION_SUCCESS,
    UPDATE_ORDER_DETAILS_ERROR,
    UPDATE_ORDER_DETAILS_STARTED,
    UPDATE_ORDER_DETAILS_SUCCESS,
    UPDATE_SEAT_IDENT_ERROR,
    UPDATE_SEAT_IDENT_STARTED,
    UPDATE_SEAT_IDENT_SUCCESS,
    SET_REQUESTED_SERVICES,
    SET_IS_PAPER_CONSENT,
} from '../types/reduxActionTypes'

const seatIdentDefault = {
    licenseNumber: '',
    issueDate: null,
    expirationDate: null,
    countryOfIssueCode: null,
    expirationDateCheckbox: false,
}

const orderDetailsDefault = {
    salesPerson: '',
    commissionNumber: '',
    vin: '',
    preferredServicePartnerId: null,
    preferredServicePartnerName: null,
    serviceScheduling: false,
    connectCapabilityStatus: null,
    brand: null,
    createdByOcwData: false,
}

const initialState = {
    loading: false,
    error: false,
    skippedSteps: [],
    customerInformation: {
        email: '',
        firstName: '',
        surname: '',
        nickname: '',
        dateOfBirth: null,
        address: {
            countryId: 0,
            addressTypeId: 1,
        },
        dialCode: '',
        phoneNumber: '',
        preferredLanguage: '',
        addressValidationRules: {},
    },
    seatIdent: seatIdentDefault,
    orderDetails: orderDetailsDefault,
    requestedServices: {
        dataProcessing: false,
        dealerMaintenanceScheduling: false,
        preferredServicePartner: false,
        seatIdent: false,
    },
    consentsType: null,
    isPaperConsent: false,
}

const userSignedOutAction = 'oidc/userSignedOut'

export const customerReducer = (state = initialState, action) => {
    let exists = false
    switch (action.type) {
        case INIT_REGISTRATION_PROCESS:
        case userSignedOutAction:
            return {
                ...initialState,
                ...action.payload,
            }
        case SET_CUSTOMER_EMAIL:
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    email: action.payload.email,
                },
            }
        case GET_CUSTOMER_STARTED:
        case CREATE_CUSTOMER_STARTED:
        case UPDATE_SEAT_IDENT_STARTED:
        case UPDATE_CUSTOMER_INFORMATION_STARTED:
        case UPDATE_ORDER_DETAILS_STARTED:
        case GET_CUSTOMER_STATUSES_STARTED:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                id: action.payload.customer.id,
                customerInformation: {
                    ...action.payload.customer.customerInformation,
                    errors: {
                        email: null,
                        firstName: null,
                        surname: null,
                        nickname: null,
                        country: null,
                        address: null,
                        dialCode: null,
                        phoneNumber: null,
                        preferredLanguage: null,
                    },
                    addressValidationRules: {},
                    country: action.payload.customer.customerInformation.address
                        ? action.payload.customer.customerInformation.address.countryId
                        : '0',
                    address: {
                        ...action.payload.customer.customerInformation.address,
                        county: action.payload.customer.customerInformation.address?.county
                            ? parseInt(action.payload.customer.customerInformation.address.county) +
                              1
                            : 0,
                    },
                },
                seatIdentStatus: action.payload.customer.seatIdentStatus,
                seatIdent: {
                    ...action.payload.customer.seatIdent,
                    expirationDateCheckbox: false,
                },
                orderDetails: action.payload.customer.orderDetails,
                requestedServices: action.payload.customer.requestedServices,
                consentsType: action.payload.customer.consentsType,
                seatConnectExistsFlag: action.payload.customer.seatConnectExistsFlag,
                seatIdentExistsFlag: action.payload.customer.seatIdentExistsFlag,
                seatGroupIdExistsFlag: action.payload.customer.seatGroupIdExistsFlag,
                enrolled: action.payload.customer.enrolled,
                registrationStartedDataLocked:
                    action.payload.customer.registrationStartedDataLocked,
            }
        case GET_CUSTOMER_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                customerJourneyStatuses: action.payload.statuses,
            }
        case CREATE_CUSTOMER_SUCCESS:
        case UPDATE_SEAT_IDENT_SUCCESS:
        case UPDATE_CUSTOMER_INFORMATION_SUCCESS:
        case UPDATE_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }
        case GET_CUSTOMER_ERROR:
        case GET_CUSTOMER_STATUSES_ERROR:
        case CREATE_CUSTOMER_ERROR:
        case UPDATE_SEAT_IDENT_ERROR:
        case UPDATE_CUSTOMER_INFORMATION_ERROR:
        case UPDATE_ORDER_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            }
        case SET_PSP:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    preferredServicePartnerId: action.payload.value.id,
                    preferredServicePartnerName: action.payload.value.name,
                },
            }
        case ADD_SKIPPED_STEP:
            exists = state.skippedSteps.find((s) => s === action.payload.value)
            const newSkippedSteps = state.skippedSteps
            if (!exists) {
                newSkippedSteps.push(action.payload.value)
            }
            return {
                ...state,
                skippedSteps: newSkippedSteps,
            }
        case REMOVE_SKIPPED_STEP:
            return {
                ...state,
                skippedSteps: state.skippedSteps.filter((s) => s !== action.payload.value),
            }
        case CLEAR_NEW_CUSTOMER:
            return initialState
        case ADD_CUSTOMER_INFO_FROM_GROUP_ID:
            return {
                ...state,
                loading: false,
                error: false,
                customerInformation: {
                    ...action.payload.customer,
                    country: action.payload.country,
                    addressValidationRules: {},
                },
            }
        case SAVE_CUSTOMER_INFORMATION:
            let newNickname = state.customerInformation.nickname
            let prefLanguage = state.customerInformation.preferredLanguage
            let newPhone = state.customerInformation.phoneNumber
            if (action.payload.name === 'firstName') {
                newNickname = `${action.payload.value.substr(
                    0,
                    98
                )} ${state.customerInformation.surname.substring(0, 1)}`
            }
            if (action.payload.name === 'surname') {
                newNickname = `${state.customerInformation.firstName.substring(
                    0,
                    98
                )} ${action.payload.value.substr(0, 1)}`
            }
            if (action.payload.name === 'nickname') {
                newNickname = action.payload.value
            }
            if (action.payload.name === 'country') {
                prefLanguage = NONE
            }
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    nickname: newNickname,
                    preferredLanguage: prefLanguage,
                    phoneNumber: newPhone,
                    [action.payload.name]: action.payload.value,
                },
            }
        case SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD:
            if (action.payload.name === 'preferredLanguage') {
                return {
                    ...state,
                    customerInformation: {
                        ...state.customerInformation,
                        [action.payload.name]: action.payload.value,
                    },
                }
            }
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    address: {
                        ...state.customerInformation.address,
                        [action.payload.name]: action.payload.value,
                    },
                },
            }
        case RESET_VALIDATION:
            const tab = action.payload.tab
            return {
                ...state,
                [tab]: {
                    ...state[tab],
                },
            }
        case REMOVE_ADDRESS_FIELDS:
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    address: null,
                },
            }
        case ADD_ADDRESS_FIELDS:
            const newAddressFields = action.payload.value
            const errorFields = Object.entries(newAddressFields)
                .filter(([, v]) => v.required === true || v.validation)
                .map(([k]) => k)
            const validationRules = {}
            Object.entries(newAddressFields)
                .filter(([, v]) => v.required === true || v.validation)
                .forEach(([k, v]) => {
                    validationRules[k] = {
                        required: v.required,
                        validation: v.validation,
                    }
                })
            let newErrors = {
                ...state.customerInformation.errors,
                address: {},
            }
            let noErrors = {
                ...state.customerInformation.noErrors,
                address: {},
            }
            errorFields.forEach((f) => {
                if (f !== 'language') {
                    newErrors.address[f] = null
                    noErrors.address[f] = null
                }
            })
            noErrors.address.countryId = null
            let newFields = {
                ...state.customerInformation,
                address: {},
            }
            Object.entries(newAddressFields).forEach(([k]) => {
                if (k !== 'language') {
                    return (newFields.address[k] =
                        state.customerInformation?.address?.[k] ||
                        (k === 'addressTypeId' || k === 'county' ? 0 : ''))
                }
            })
            newFields.errors = {
                ...newErrors,
            }
            newFields.noErrors = {
                ...noErrors,
            }
            newFields.addressValidationRules = validationRules
            return {
                ...state,
                customerInformation: newFields,
            }
        case ADD_ADDRESS_FIELDS_ON_UPDATE:
            const newCustomerInformationAddressFields = action.payload.value
            const customerInformationErrorFields = Object.entries(
                newCustomerInformationAddressFields
            )
                .filter(([, v]) => v.required === true || v.validation)
                .map(([k]) => k)

            const customerInformationValidationRules = {}
            Object.entries(newCustomerInformationAddressFields)
                .filter(([, v]) => v.required === true || v.validation)
                .forEach(([k, v]) => {
                    customerInformationValidationRules[k] = {
                        required: v.required,
                        validation: v.validation,
                    }
                })
            let newCustomerInformationErrors = {
                ...state.customerInformation.errors,
                address: {},
            }
            let noCustomerInformationErrors = {
                ...state.customerInformation.noErrors,
                address: {},
            }
            customerInformationErrorFields.forEach((f) => {
                if (f !== 'language') {
                    newCustomerInformationErrors.address[f] = null
                    noCustomerInformationErrors.address[f] = null
                }
            })
            noCustomerInformationErrors.address.countryId = null
            let newCustomerInformation = {
                ...state.customerInformation,
            }
            newCustomerInformation.errors = {
                ...newCustomerInformationErrors,
            }
            newCustomerInformation.noErrors = {
                ...noCustomerInformationErrors,
            }
            newCustomerInformation.addressValidationRules = customerInformationValidationRules
            return {
                ...state,
                customerInformation: newCustomerInformation,
            }
        case SAVE_SEAT_IDENT:
            return {
                ...state,
                seatIdent: {
                    ...state.seatIdent,
                    [action.payload.name]: action.payload.value,
                },
            }
        case REMOVE_SEAT_IDENT_DATA:
            return {
                ...state,
                seatIdent: seatIdentDefault,
            }
        case SAVE_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    [action.payload.name]: action.payload.value,
                },
            }
        case SET_REQUESTED_SERVICES:
            return {
                ...state,
                requestedServices: action.payload,
            }
        case SET_IS_PAPER_CONSENT:
            return {
                ...state,
                isPaperConsent: action.payload,
            }
        default:
            return state
    }
}
