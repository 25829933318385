import React from 'react'
import styled from 'styled-components'
import { WarningIcon } from './Icons/WarningIcon'
import { DateTime, Duration, Interval } from 'luxon'
import { useTranslation } from 'react-i18next'
import { journeyStatusValuesEnum, TRANSLATIONS_NAMESPACE } from '../constants/global'

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 100px;
    max-width: 300px;
    padding: 20px;
    border: 3px solid lightgray;
`

const Heading = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 10px;
`
const Icon = styled.div`
    text-transform: uppercase;
    margin-right: 10px;
`
const Message = styled.div`
    font-size: 18px;
    text-transform: uppercase;
`
const Content = styled.div`
    font-size: 14px;
`

const carDeliveryDateContent = {
    title: 'general.customer.taskBox.carDeliveryDate.heading',
    description: 'general.customer.taskBox.carDeliveryDate.description',
}

const processIncompleteContent = {
    title: 'general.customer.taskBox.processIncomplete.heading',
    description: 'general.customer.taskBox.processIncomplete',
}

export default function TaskBox({ customer, statuses }) {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { deliveryDateToImporter } = customer.orderDetails
    const { enrolled } = customer

    const sevenDayAgo = Interval.before(DateTime.utc(), Duration.fromISO('P7D'))
    const now = DateTime.local().toUTC()

    let taskBoxData = null
    if (deliveryDateToImporter > sevenDayAgo.start && deliveryDateToImporter <= now) {
        taskBoxData = carDeliveryDateContent
    } else if (
        deliveryDateToImporter < sevenDayAgo.start &&
        (!enrolled ||
            statuses.vehicleJourneyStatuses[0]?.preferredServicePartnerSet.state ===
                journeyStatusValuesEnum.COMPLETED ||
            statuses.vehicleJourneyStatuses[0]?.dealerMaintenanceSchedulingEnablement.state ===
                journeyStatusValuesEnum.COMPLETED ||
            statuses.customerJourneyStatuses.seatIdentVerificationStatus.state ===
                journeyStatusValuesEnum.COMPLETED)
    ) {
        taskBoxData = processIncompleteContent
    }

    return taskBoxData ? (
        <Root>
            <Heading>
                <Icon>
                    <WarningIcon width={35} />
                </Icon>
                <Message>{t(taskBoxData.title)}</Message>
            </Heading>
            <Content>{t(taskBoxData.description)}</Content>
        </Root>
    ) : null
}
